import * as React from 'react';
import { queryValueToString } from '@lib/queryValueToString';
import { GetServerSidePropsContext } from 'next';
import { auth } from '@acme/auth';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { Toaster } from '@components/ui/toaster';
import { LoginCard } from '@components/Auth/LoginCard';

interface SamplePhoto {
  url: string;
}

const SamplePhotos: readonly SamplePhoto[] = [
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/26b01e2a-7912-4c28-4b3b-76934ac8db00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d092ea17-2ce6-4e6e-4c86-c92b26585200/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d54b1fbe-9716-4905-8c43-dddf4beb6700/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/2b3d6ffd-40d6-41c9-2803-d6270eb10300/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/8e8bdf73-65f4-4279-3f2d-ab554750a300/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/5f79b33f-29da-45f5-de26-22c0f070ba00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/25b41506-c150-4931-9762-d6f2c9a98c00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/aebe044e-f74e-4b16-c907-1ab045577100/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/a29e1c5e-21a4-4443-92cd-45310f45df00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/60a2dde5-0295-4f88-976d-11f0d0834300/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/7dc405ce-efff-45bc-507a-39df8a4dc700/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/4b40e38d-3fc7-4996-e32c-ace96541b100/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/05eea316-f0cf-4178-2b88-e4f73b4d1400/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/e13dc49a-5031-48e7-ea60-f3eece69d100/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/76835075-2989-499c-0649-5d4db5473b00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/34766c89-7430-4eb2-67bf-039592c4fd00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/bbdb4c38-e8bd-4b35-b77a-464018e31100/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/49b98599-5c85-4cc6-1eef-d3a311cec200/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/bfce95f7-cadd-4c06-c36f-7b6a510d5e00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/72bda3d5-2c26-4cf8-9a76-23c83f1e7500/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/cd8452ff-eaee-4d16-f4d6-3fc996879c00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/9f495809-9ea7-4fe5-16b3-9a2fb4a2a500/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/8152d9b3-856d-442b-43c9-985006966300/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/9eab2b98-3817-4825-0ba2-68d0b08f5800/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/06cd623e-69e6-4a2d-a437-0b6dfabb2300/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d88c278b-35e0-4b52-f63a-c0d05d7ba800/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/0b112e89-3b1b-4b37-c485-c3caa8df1f00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/32155a91-ddc3-485d-5e22-6cce7ff4f700/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3a7a93ae-0035-4c70-e2d7-1a008be73200/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/80b90563-606a-4a90-e48c-171248939200/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d0cd10b4-9dcb-40bb-acd4-4096e3d4b200/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/8cf81c63-41f7-46dd-4bb1-eee513f46200/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3881361b-146d-4eb0-7785-fec19cef3a00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/28782968-7a67-49f1-e518-72d115807c00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/52f65cec-5ff6-464a-ddf1-57c88f94b100/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/bfbf70c7-b1ce-45a1-fab5-70bb47738b00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/6ffdf9d9-3bb6-48bf-dd77-2d2ea74d1e00/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/c95a7cc5-e3f8-4e4b-13af-94444af13300/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/cfd71874-31fe-4f14-3522-0367947b2900/public' },
  { url: 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/f2b80aea-1fa1-4e72-71ac-7787f0369200/public' }
];

const MotionImage = motion.img;

interface ImageColumnProps {
  images: SamplePhoto[];
  speed?: number;
  offset?: string;
}

const ImageColumn: React.FC<ImageColumnProps> = ({ images, speed = 1, offset = '0' }) => {
  const duplicatedImages = [...images, ...images];

  return (
    <motion.div
      className={`flex flex-col gap-4 ${offset}`}
      animate={{ y: ['0%', '-50%'] }}
      transition={{
        y: {
          repeat: Infinity,
          repeatType: 'loop',
          duration: 120 / speed,
          ease: 'linear'
        }
      }}
    >
      {duplicatedImages.map((photo, index) => (
        <MotionImage
          key={`${photo.url}-${index}`}
          src={photo.url}
          alt="Sample Photo"
          className="w-full aspect-[2/3] rounded-xl object-cover"
        />
      ))}
    </motion.div>
  );
};

const generateColumnImages = (columnIndex: number): SamplePhoto[] => {
  return Array.from({ length: 6 }, (_, i) => {
    const photoIndex = (columnIndex * 6 + i) % SamplePhotos.length;
    return SamplePhotos[photoIndex]!;
  });
};

export default function Login() {
  const router = useRouter();
  const error = router.query.error as string | undefined;

  const callbackUrl = React.useMemo(() => {
    const redirectQuery = router.query.redirect;

    if (typeof redirectQuery === 'string') {
      const url = new URL(redirectQuery, 'http://dummy-base');
      const path = url.pathname + url.search;
      const separator = path.includes('?') ? '&' : '?';
      return `${path}${separator}authSuccess=true`;
    }
    return '/dashboard?authSuccess=true';
  }, [router.query.redirect]);

  const promoCode = React.useMemo(() => {
    if (router.query.redirect) {
      const redirectUrl = new URL(router.query.redirect as string, 'http://dummy-base');
      return redirectUrl.searchParams.get('code');
    }
    return null;
  }, [router.query.redirect]);

  const columnCount = 6;
  const columns: SamplePhoto[][] = Array.from({ length: columnCount }, (_, i) => generateColumnImages(i));
  const columnSpeeds = Array.from({ length: columnCount }, () => 0.8 + Math.random() * 0.4);
  const columnOffsets = ['-mt-20', '-mt-32', '-mt-16', '-mt-28', '-mt-12', '-mt-24'];

  return (
    <div className="flex items-center justify-center min-h-screen bg-slate-100 dark:bg-background relative overflow-hidden">
      <LoginCard
        error={error}
        promoCode={promoCode}
        callbackUrl={callbackUrl}
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 bg-card/80 dark:bg-card/20 backdrop-blur-md
"
      />
      <div className="absolute inset-0 flex gap-6 px-6 opacity-10">
        {columns.map((columnImages, index) => (
          <div
            key={index}
            className={`flex-1 ${index >= 2 ? 'hidden md:block' : ''} ${index >= 5 ? 'hidden xl:block' : ''}`}
          >
            <ImageColumn images={columnImages} speed={columnSpeeds[index]} offset={columnOffsets[index]} />
          </div>
        ))}
      </div>
      <Toaster />
    </div>
  );
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const session = await auth(ctx);

  if (session) {
    return {
      redirect: {
        permanent: false,
        destination: `/dashboard`
      }
    };
  }

  return {
    props: {
      query: queryValueToString(ctx.query.query)
    }
  };
}
